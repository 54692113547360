<template>
  <div class="ContactSection">
    <template v-if="isMobile(true)">
      <img class="imagesInsert" src="https://wp-ocssport.ocs-sport.com/golfpark/wp-content/uploads/sites/4/2024/06/Golfpark_4.png">
    </template>
    <div class="container">
      <h2 class="ContactTitle">Support</h2>
      <p class="WhiteStandard-text contact-width">Support Text</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Support',
  components: {
  },
  data: function() {
    return {
      acf: [],
    };
  },
  methods: {
    isMobile() {
        if( screen.width <= 500 ) {
            return true;
        }
        else {
            return false;
        }
    },
  },
  mounted() {
    axios //add API Call
        .get(
            process.env.VUE_APP_API_URL + 'racepark' + "ocs-studios/wp-json/wp/v2/pages/94"
        ) // Page number changed on each page based on JSON page id you are calling
        .then((response) => (
            this.acf = response.data
        )); //API data wanted pulled out
  },
}
</script>

<style scoped>
.title {
  color: black;
}
.ContactSection {
  background-color: #1A1A1A;
}
.ContactTitle {
  color: #fff;
  font-size: 65px;
  padding-top: 80px;
  font-family: "korolev", sans-serif;
  font-weight: 700;
  font-style: italic;
}
.WhiteStandard-text {
  font-size: 24px;
  color: #fff;
  font-family: "noto-sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.contact-width {
  width: 100%;
  text-align: center;
  margin: auto;
  padding-top: 30px;
  padding-bottom: 70px;
}
@media only screen and (max-width: 768px) {
  .ContactTitle {
    color: #fff;
    font-size: 40px;
  }
  .WhiteStandard-text {
    font-size: 20px;
    color: #fff;
    font-family: "noto-sans", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  .imagesInsert {
    width: 100%;
  }
}
</style>
